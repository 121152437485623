import homepageBanner from '../../images/banner.png';
import homepageContactSectionBackground from '../../images/hard-adv-5.jpg';
import section1Img1 from '../../images/hard-adv-1.jpg';
import section1Img2 from '../../images/hard-adv-2.jpg';
import section1Img3 from '../../images/hard-adv-3.jpg';

import tireTracks from '../../images/tire-track.png';

import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import AppConfig from '../../utils/appconfig';
import { useEffect } from 'react';
import { SectionTitle } from '../../components/sectionTitle';
import { Banner } from '../../components/banner';
import { LinkButton } from '../../components/linkButton';
import { ContactSection } from '../../components/contactSection';
import { PartnerSection } from '../../components/partnerSection';
import { scrollBodyToTop } from '../../utils/utils';

const currentPage: string = 'home';

export function HomePage() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Strona główna`;

    scrollBodyToTop();
  }, []);
  
  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <Banner title={<>CZY JESTEŚ <span className="text-color--primary">GOTOWY NA</span> TO, ABY POCZUĆ <span className="text-color--primary">ADRENALINĘ</span></>}
        backgroundImage={homepageBanner} />

      <section className='content-wrapper home-section-1'>
        <div className="home-section-1__images">
          <img className="home-section-1__images--img-1" src={section1Img1} alt="Motocykl przejeżdżający przez kałużę" />
          <img className="home-section-1__images--img-3" src={section1Img3} alt="Motocykl i pojazd UTV" />
          <img className="home-section-1__images--img-2" src={section1Img2} alt="Uczestnicy imprezy na górze" loading="lazy" />
        </div>

        <div className="home-section-1__text">
          <SectionTitle title={<><span className="text-color--primary">HARD ADV</span> WEEKEND 2025</>} customClassName={'section-title__wrapper--home'} />

          <p>Czy jesteś gotowy na to, aby poczuć adrenalinę, podzielić się swoją pasją do motocykli i spotkać innych zwariowanych ludzi? Nie czekaj, zarezerwuj swoje miejsce już teraz i dołącz do nas na tej niesamowitej przygodzie. Czekamy na Ciebie!</p>
                    
          <h3>PAMIĘTAJ, ŻYCIE TO PODRÓŻ, A NAJLEPSZE HISTORIE ZAWSZE ZACZYNAJĄ SIĘ NA MOTOCYKLU.</h3>
          
          <p>Do zobaczenia na Zlocie!</p>

          <LinkButton buttonText={'ZAPISZ SIĘ TUTAJ'} disabled={false} linkTo={'https://raceduck.app/event/HARD%20ADV%20WEEKEND%202025%20-%20MYSTERY'} 
            target='_blank' customClassName="home-section-1__button-link-wrapper"
          />
        </div>
      </section>

      <section className='content-wrapper home-section-2'>
        <SectionTitle title={<>NASI <span className="text-color--primary">PARTNERZY</span> 2024</>} customClassName={'section-title__wrapper--home'} />

        <PartnerSection />

        <img src={tireTracks} alt="Ślady opon terenowych" className="home-section-2__tire-tracks" loading="lazy" />
      </section>

      <ContactSection title={<>SKONTAKTUJ SIĘ Z NAMI</>} backgroundImage={homepageContactSectionBackground} />

      <Footer currentPage={currentPage} />
    </div>
  );
}
